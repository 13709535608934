import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ResizeObserverModule, SplitIOService, NgKLoggerService } from 'lib-kinponents';
import '@kin/web-components/dist/kin-non-prod-banner/kin-non-prod-banner';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BeginComponent } from './begin/begin.component';
import { DevEnvGuard } from './shared/guards/dev-env.guard';
import { NullTokenGuard } from './shared/guards/null-token.guard';
import { RequestInterceptor } from './shared/interceptors/request.interceptor';
import { AuthService } from './shared/services/auth.service';
import { ExternalScriptService } from './shared/services/external-scripts.service';
import { FullstoryService } from './shared/services/fullstory.service';
import { GuidService } from './shared/services/guid.service';
import { LoaderService } from './shared/services/loader.service';
import { MessageService } from './shared/services/message.service';
import { PaymentService } from './shared/services/payment.service';
import { RequestService } from './shared/services/request.service';
import { ResumeMyQuoteModule } from './resume-my-quote/resume-my-quote.module';
import { SessionStorageService } from './shared/services/session-storage.service';
import { LocalStorageService } from './shared/services/local-storage.service';
import { TagManagerService } from './shared/services/tag-manager.service';
import { SharedModule } from './shared/shared.module';
import { appInitializerFactory } from './app-config/app-initializer-factory/app-initializer.factory';
import { QuotingUiRedirectService } from './shared/services/quoting-ui-redirect.service';

// This enables us to turn off Angular animations when prefers-reduced-motion is on.
export function prefersReducedMotion(): boolean {
  const mediaQueryList = window.matchMedia('(prefers-reduced-motion)');

  return mediaQueryList.matches;
}

@NgModule({
  declarations: [AppComponent, BeginComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    ResumeMyQuoteModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule.withConfig({
      disableAnimations: prefersReducedMotion(),
    }),
    ResizeObserverModule,
  ],
  providers: [
    MessageService,
    AuthService,
    ExternalScriptService,
    RequestService,
    SessionStorageService,
    TagManagerService,
    PaymentService,
    FullstoryService,
    GuidService,
    NullTokenGuard,
    DevEnvGuard,
    LoaderService,
    LocalStorageService,
    SplitIOService,
    NgKLoggerService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [
        SplitIOService,
        GuidService,
        FullstoryService,
        NgKLoggerService,
        QuotingUiRedirectService,
      ],
      multi: true,
    },
    { provide: Window, useValue: window },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
