// Could move this into a more configurable area.

export const constants = {
  OLDEST_AGE_ACCEPTED: 122,
  NEWEST_AGE_ACCEPTED: 18,
  REQUEST_RETRIES: 2,
  PHONE_NUMBER: '(855) 717-0022',
  SUPPORT_EMAIL: 'sales@kin.com',
  REGEX: {
    phone: /^\(?([2-9][0-9]{2})\)?[-. ]?([2-9](?!11)[0-9]{2})[-. ]?([0-9]{4})$/,
    phoneWithoutPunctuation:
      /^((\+\d{1,2}|1)[\s.-]?)?\(?[2-9](?!11)\d{2}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
    email:
      /[a-z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i,
    emailDomain: /([^@]*$)/g,
  },
  DEFAULT_PAYMENT_SCHEDULE: 'full',
  PAYMENT_PATHS: {
    card: '/quote/payment/cc',
    escrow: '/quote/payment/mortgage',
  },
};

export const VALIDATION_MESSAGES = {
  required: 'This field is required.',
  maxlength: 'This field has too many characters.',
  minlength: 'This field has not enough characters.',
  dobInvalid: 'The acceptable age range is from 18 to 122.',
  dateInvalid: 'The input given is not a valid date.',
  emailInvalid: 'This is not a valid email address.',
  minValue: 'This field is too low.',
  maxValue: 'This field is too high.',
  minLength: 'This field has not enough characters.',
  maxLength: 'This field has too many characters.',
  minAgeDays: 'This field is out of the acceptable range.',
  maxAgeDays: 'This field is out of the acceptable range.',
  minAgeYears: 'This field is out of the acceptable range.',
  maxAgeYears: 'This field is out of the acceptable range.',
  phoneInvalid: 'This phone number is not valid.',
  creditCardNumberInvalid: 'The credit card number is not valid.',
  creditCardNameInvalid: "The cardholder's name is not valid.",
  creditCardDateInvalid: 'The expiration date is not valid.',
  creditCardCVVInvalid: 'The CVV is not valid',
  creditCardZipInvalid: 'The billing zip code is not valid',
};

export const DISCLAIMER_MESSAGES = {
  pricesAreEstimates:
    'Prices are estimates and are subject to change until our underwriting verification of the data used to rate your policy is completed, which may include, but is not limited to verification of your claims history, your insurance score, a property inspection, and other underwriting criteria, as applicable. Changes made to coverage or deductible selections will affect pricing. Please note a Wind Mitigation credit amount may vary, pending receipt of any state-mandated inspection form.',
  oldRoofMayBeHo3Eligible:
    'With your roof being at least 15 years old, Florida law states you have the option to have your roof inspected by an authorized inspector of our choosing but at your own expense. If the inspection finds that your roof still has 5 years or more of useful life left, you may be eligible for an HO3 policy with Kin. Please see <a class="text__link" href="http://www.kin.com/inspection-resources">kin.com/inspection-resources</a> for a list of approved inspectors.',
  texasWindHailDeductible: `<br><br>Wind/Hail deductible options are based on where you live, and a minimum of 5% may be required. Please note, you may not be eligible for all of the options shown below. To discuss deductible options tailored to your area, please contact a licensed agent or proceed with your quote.`,
  californiaBottomDisclaimer:
    'In California, Kin refers to Kin Insurance Services. Kin Insurance Services is a California surplus lines broker (license #0L32036). Coverage is underwritten by a company that is not licensed or regulated by the California Insurance Commissioner.',
  californiaAOPDisclaimer:
    '<strong>*Standard (AOP) deductible options are based on where you live, and a minimum of either 1% or $2,500 may be required for some customers.</strong> Please note, you may not be eligible for all of the options shown. To discuss deductible options tailored to you, please contact a licensed agent or proceed with your quote.',
  californiaCoverageCDisclaimer:
    '<strong>*Coverage C options are based on where you live, and a minimum of 20% may be required.</strong> Please note, you may not be eligible for all of the options shown. To discuss deductible options tailored to your area, please contact a licensed agent or proceed with your quote.',
};
