import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  Input,
  QueryList,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { AriaHelper } from '../../utils/aria-helper';
import { FieldsetGridItemDirective } from './fieldset-grid-item/fieldset-grid-item.directive';

/**
 * This component is used to render a `<fieldset>` with a grid of form controls.
 * Form controls are projected into this component wrapped in `<ng-template>` with a ngkFieldsetGridItem directive.
 * @example
 * ```html
 * <ngk-fieldset-grid>
 *   <ng-template ngkFieldsetGridItem let-itemId>
 *     <ngk-example-control [id]="itemId"></ngk-example-control>
 *   </ng-template>
 * </ngk-fieldset-grid>
 *```
 * `itemId` is available in the template context to easily set an indexed ID for form controls in the fieldset,
 * as exemplified by [W3's Grouping Guide](https://www.w3.org/WAI/tutorials/forms/grouping/).
 */
@Component({
  selector: 'ngk-fieldset-grid',
  templateUrl: './fieldset-grid.component.html',
  styleUrls: ['./fieldset-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldsetGridComponent {
  /**
   * We need this disclaimer as part of HD3 product expansion (see QT-1221).
   *
   * IT IS FRAGILE
   * and relies on our ability to key on the generated `id` within this context in order to intercept the `solar_panel_status` question and display the disclaimer. See `isSolarPanelStatus()` below.
   *
   * There was an attempt to make this an @Input we could pass/set, and it quickly grew in scope beyond the expectations of this effort. While question manipulation with the sort util looked like another good place, the context needed is not available within its execution scope. Plus, we're trending towards sunsetting this application in the very near future (weeks 🤞).
   */
  public solarDisclaimer: string =
    'Optional coverage is available for owned solar panels. Leased panels are not eligible.';

  /**
   * Queries for the FieldsetGridItemDirective to get the templateRef of each grid item.
   * Used to render the grid items via ngTemplateOutlet and calculate the number of grid columns.
   */
  @ContentChildren(FieldsetGridItemDirective) gridItems!: QueryList<FieldsetGridItemDirective>;

  /**
   * ID of fieldset element.
   */
  @Input() id: string = AriaHelper.getGeneratedId('ngk-fieldset-grid');

  /**
   * Visually hidden by default, sets the text of the fieldset legend.
   * Highly recommended this is set for accessibility.
   *
   * A template can also be projected as the label.
   */
  @Input() label: string = '';

  /**
   * Show or hide legend text. Defaults to false, or hidden.
   */
  @Input() showLabel: boolean = false;

  /**
   * The AbstractControl that contains the FormControls of the grid items.
   * Used to enable validation messages of the fieldset as a whole.
   */
  @Input() form?: AbstractControl;

  /**
   * Show or hide validation messages based off FormControl.errors, defaults to true. Intended for compatibility with app-funnel.
   */
  @Input() showValidationMessages: boolean = true;

  /**
   * Limits columns of the grid. Each card is in a new column until the limit, at which point a new row begins.
   */
  @Input() columnLimit: 1 | 2 | 3 | 4 = 4;

  /**
   * If true, the last row of items will be centered if it has fewer items than columns. Defaults to false.
   */
  @Input() centerIncompleteRow: boolean = false;

  get describedBy(): string | null {
    return AriaHelper.buildDescribedByString(this.id, {
      hasErrors: this.form?.invalid,
    });
  }

  /**
   * Used to set the grid size of each radio button, effectively making columns.
   * Between the number of options and the configured limit, the smaller number is used.
   */
  get gridItemSize() {
    return 12 / Math.min(this.gridItems.length, this.columnLimit);
  }

  /**
   * Used to determine when we have the HD3 solar question in order to show the solar disclaimer for this radio question type (there are multiple kinds of `radio` questions)
   */
  isSolarPanelStatus(): boolean {
    return !!(this.id && this.id === 'quote_property_information_solar_panel_status');
  }
}
